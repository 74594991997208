<template>
  <div id="school">
    <template v-if="loading">
      <section class="hero is-medium">
        <div class="hero-body">
          <loading />
        </div>
      </section>
    </template>
    <template v-else>
      <vue-navs
        site-name="SIMSED"
        :user="user"
        :side-bar-type="side_bar_type"
        :side-bar-items="side_bar_items"
        :nav-bar-items="nav_bar_items"
        :nav-bar-style="navBarStyle"
        :key="user.role"
        v-if="user"
      >
        <template v-slot:side-bar-info>
          <div class="has-text-centered">
            <figure class="image is-64x64 is-inline-block">
              <img
                class="is-rounded"
                :src="user.avatar"
                alt="user_img"
                v-if="user.avatar"
              />
              <img
                class="is-rounded"
                src="@/assets/images/default_avatar.png"
                alt="avatar"
                v-else
              />
            </figure>
          </div>
          <hr />
          <p class="user-slot">{{ user.email }}</p>
          <p class="user-slot">{{ $store.getters.getTerm.session_title }}</p>
          <p class="user-slot">
            {{ $store.getters.getBilling.remaining_days }} days remaining
          </p>
          <hr />
        </template>
      </vue-navs>
      <section
        class="section"
        style="padding-top: 24px; padding-left: 0px; padding-right: 0px"
      >
        <orange-bar
          :page-title="$store.getters.getPageTitle"
          :sub-menus="subMenus"
          @show-parent="showParent()"
        />
      </section>
      <section class="section" style="padding-top: 0px">
        <router-view />
      </section>
    </template>
  </div>
</template>

<script>
import {
  fetchUser,
  fetchSchool,
  fetchBilling,
  fetchTerm,
} from '@/assets/js/app_info.js'
import StoreManager from '@/store/store_manager'

export default {
  name: 'school',
  data() {
    return {
      loading: true,
      user: null,
      school: null,
      term: null,
      billing: null,
      side_bar_type: 'above',
      side_bar_items: [],
      nav_bar_items: [
        {
          name: 'Account',
          icon: 'fas fa-user',
          route: '/account',
          action: () => {
            this.account()
          },
        },
        {
          name: 'Logout',
          icon: 'fas fa-sign-out-alt',
          route: '/home',
          action: () => {
            this.logOut()
          },
        },
      ],
      navBarStyle: {
        width: '100%',
        padding: '10px',
        opacity: '0.8',
        background: `linear-gradient(to right, rgb(11, 165, 236), rgb(255, 255, 255))`,
      },
    }
  },
  computed: {
    subMenus() {
      return this.$store.getters.getSubMenus
    },
  },
  methods: {
    account() {
      this.$router.push(`/school/${this.school.id}/account`)
    },
    logOut() {
      this.$root.$emit('loggedOut')
      this.$store.dispatch('logOut')
    },
    showParent() {
      this.$store.commit('setShowParent', true)
    },
    sideBarItems() {
      let menu_items = []
      if (!this.user) return
      if (this.user.role === 'host' || this.user.role === 'admin') {
        menu_items = [
          {
            name: 'Dashboard',
            route: `/school/${this.school.id}/dashboard`,
            icon: 'fas fa-tv',
          },
          {
            name: 'Billing',
            route: `/school/${this.school.id}/subscriptions`,
            icon: 'fas fa-dollar-sign',
          },
          {
            name: 'Structure',
            route: '#',
            icon: 'fas fa-cubes',
            open_menu: false,
            sub_menus: [
              {
                name: 'Terms',
                route: `/school/${this.school.id}/terms`,
              },
              {
                name: 'Class Groups',
                route: `/school/${this.school.id}/class_groups`,
              },
              {
                name: 'School Classes',
                route: `/school/${this.school.id}/school_classes`,
              },
              {
                name: 'Subjects',
                route: `/school/${this.school.id}/subjects`,
              },
              {
                name: 'Assigned Subjects',
                route: `/school/${this.school.id}/assigned_subjects`,
              },
              {
                name: 'Assigned Classes',
                route: `/school/${this.school.id}/assigned_classes`,
              },
            ],
          },
          {
            name: 'Users',
            route: '#',
            icon: 'fas fa-users',
            open_menu: false,
            sub_menus: [
              {
                name: 'Administrators',
                route: `/school/${this.school.id}/admins`,
              },
              {
                name: 'Educators',
                route: `/school/${this.school.id}/educators`,
              },
              {
                name: 'Students',
                route: `/school/${this.school.id}/students_in_school`,
              },
              { name: 'Parents', route: `/school/${this.school.id}/parents` },
            ],
          },
          {
            name: 'Ate Results',
            route: '#',
            icon: 'fas fa-file-alt',
            open_menu: false,
            sub_menus: [
              {
                name: 'Results',
                route: `/school/${this.school.id}/result_class/0`,
              },
              {
                name: 'Result Comments',
                route: `/school/${this.school.id}/result_comments`,
              },
              {
                name: 'Result Psychomotors',
                route: `/school/${this.school.id}/result_psychomotors`,
              },
              {
                name: 'Attendances',
                route: `/school/${this.school.id}/attendances`,
              },
              {
                name: 'Fast Attendance',
                route: `/school/${this.school.id}/fast_attendance`,
              },
              {
                name: 'Comments',
                route: `/school/${this.school.id}/comments`,
                has_divider: true,
              },
              {
                name: 'Fast Comments',
                route: `/school/${this.school.id}/fast_comments`,
                has_divider: true,
              },
              {
                name: 'Psychomotors',
                route: `/school/${this.school.id}/psychomotors`,
              },
              {
                name: 'Fast Psychomotors',
                route: `/school/${this.school.id}/fast_psychomotors`,
              },
              // { name: "ATE Reports", route: "/ate_reports", has_divider: true },
              {
                name: 'ATE Assessment',
                route: `/school/${this.school.id}/ate_assessment`,
              },
            ],
          },
          {
            name: 'Add-on Modules',
            route: '#',
            icon: 'fas fa-cog',
            open_menu: false,
            sub_menus: [
              {
                name: 'Fee Management',
                route: `/school/${this.school.id}/fee_management`,
              },
              {
                name: 'E-Courses',
                route: `/school/${this.school.id}/e_courses`,
              },
            ],
          },
          {
            name: 'Preferences',
            route: '#',
            icon: 'fas fa-cog',
            open_menu: false,
            sub_menus: [
              {
                name: 'Grading scales',
                route: `/school/${this.school.id}/grading_scales`,
              },
              {
                name: 'Graduates',
                route: `/school/${this.school.id}/graduates`,
              },
              {
                name: 'Withdrawals',
                route: `/school/${this.school.id}/withdrawals`,
              },
              {
                name: 'ATE Policies',
                route: `/school/${this.school.id}/ates_policies`,
              },
              // {
              //   name: "School Calendar",
              //   route: `/school/${this.school.id}/school_calendar`
              // }
            ],
          },
        ]
      } else if (this.user.role === 'educator') {
        menu_items = [
          {
            name: 'Dashboard',
            route: `/school/${this.school.id}/dashboard`,
            icon: 'fas fa-tv',
          },
          {
            name: 'New ATE',
            route: `/school/${this.school.id}/new_ate`,
            icon: 'fas fa-cubes',
          },
          {
            name: 'ATE Unmarked',
            route: `/school/${this.school.id}/ate_unmarked`,
            icon: 'fas fa-cubes',
          },
          // {
          //   name: "E Courses",
          //   route: `/school/${this.school.id}/e_courses`,
          //   icon: "fas fa-cubes"
          // },
          {
            name: 'Assigned Classes',
            route: `/school/${this.school.id}/educator_assigned_classes`,
            icon: 'fas fa-cubes',
          },
          {
            name: 'ATE Result',
            route: '#',
            icon: 'fas fa-cubes',
            open_menu: false,
            sub_menus: [
              {
                name: 'Results',
                route: `/school/${this.school.id}/result_class/0`,
              },
              {
                name: 'Result Comments',
                route: `/school/${this.school.id}/result_comments`,
              },
              {
                name: 'Result Psychomotors',
                route: `/school/${this.school.id}/result_psychomotors`,
              },
              {
                name: 'Attendances',
                route: `/school/${this.school.id}/attendances`,
              },
              {
                name: 'Fast Attendance',
                route: `/school/${this.school.id}/fast_attendance`,
              },
              {
                name: 'Fast Comments',
                route: `/school/${this.school.id}/fast_comments`,
              },
              {
                name: 'ATE Assessment',
                route: `/school/${this.school.id}/ate_assessment`,
              },
            ],
          },
        ]
      } else if (this.user.role === 'student') {
        menu_items = [
          {
            name: 'Dashboard',
            route: `/school/${this.school.id}/dashboard`,
            icon: 'fas fa-tv',
          },
          {
            name: 'E Courses',
            route: `/school/${this.school.id}/e_courses`,
            icon: 'fas fa-cubes',
          },
          {
            name: 'ATE Result',
            route: '#',
            icon: 'fas fa-cubes',
            open_menu: false,
            sub_menus: [
              {
                name: 'Result',
                route: `/school/${this.school.id}/school_class/${this.user.context.school_class_id}/result_info/${this.user.context.student_id}`,
              },
            ],
          },
        ]
      } else if (this.user.role === 'parent') {
        menu_items = [
          {
            name: 'Dashboard',
            route: `/school/${this.school.id}/dashboard`,
            icon: 'fas fa-tv',
          },
          {
            name: 'Students',
            route: `/school/${this.school.id}/parents_students/${this.user.context}`,
            icon: 'fas fa-users',
          },
          {
            name: 'Pay School Fees',
            route: `/school/${this.school.id}/dashboard`,
            icon: 'fas fa-dollar-sign',
          },
          {
            name: 'Feedback',
            route: `/school/${this.school.id}/dashboard`,
            icon: 'far fa-comments',
          },
        ]
      }
      return menu_items
    },
  },
  created() {
    this.$root.$emit('loggedIn')
  },
  mounted() {
    fetchSchool().then((school) => {
      this.school = school
    })

    fetchUser().then((user) => {
      this.user = user
      this.loading = false
      this.side_bar_items = this.sideBarItems()
    })

    fetchBilling().then((billing) => {
      this.$store.commit('setBilling', billing)
    })

    fetchTerm().then((term) => {
      this.$store.commit('setTerm', term)
    })
  },
}
</script>

<style lang="scss">
.user-slot {
  font-size: 0.75em;
  padding: 5px;
  margin-bottom: 10px;
  color: #eee;
}

ul {
  li {
    a {
      color: #eee;
    }
  }
}

.menu-body {
  font-size: 0.85em;
  a {
    color: #000;
  }
  a:hover {
    color: #fff;
  }
  background-color: #fff;
}

.small-link {
  font-size: 0.85em;
}
</style>
